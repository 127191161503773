import isObject from 'lodash/isObject';

import { ReadwiseFetchServerError } from '../../utils/Errors';
import getServerBaseUrl from '../../utils/getServerBaseUrl.platform';
import requestWithAuth from '../../utils/requestWithAuth.platformIncludingExtension';
import database from '../database';
import { globalState } from '../models';
import { updateGeneratedDocumentSummary } from '../stateUpdaters/persistentStateUpdaters/documents/summary';
import {
  resetDocumentSummaryGeneration,
  startDocumentSummaryGeneration,
  stopDocumentSummaryGeneration,
} from '../stateUpdaters/transientStateUpdaters/documentSummary';
import { createToast } from '../toasts.platform';
import { Prompt, PromptModel } from './types';

function errorIncludesResponse(error: unknown): error is ReadwiseFetchServerError {
  return isObject(error) && Object.hasOwn(error, 'response');
}

export function canUseOpenAIModel(model: PromptModel) {
  const hasOpenAIApiKey = globalState((state) => Boolean(state.persistent.settings.openai?.apiKey));
  return model === PromptModel.GPT_35_TURBO || hasOpenAIApiKey;
}

export async function generateSummary(documentId: string, prompt: Prompt, userInteraction = 'unknown') {
  try {
    await startDocumentSummaryGeneration(userInteraction);

    const result = await requestWithAuth(`${getServerBaseUrl()}/reader/api/completion`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      body: JSON.stringify({
        template: {
          documentId,
          model: prompt.model,
          prompt: prompt.template,
          systemPrompt: prompt.system,
        },
      }),
    });

    const json = await result.json();
    const summary = json.gpt3 && json.gpt3.length > 0 ? json.gpt3 : '';

    const { userEvent } = await updateGeneratedDocumentSummary(documentId, summary);
    await stopDocumentSummaryGeneration(userInteraction);

    return { summary, userEvent };
  } catch (error) {
    // see if we got a error message through the response object
    if (errorIncludesResponse(error)) {
      const response = await error.response.json();
      createToast({ content: response.error, category: 'error' });
    } else {
      createToast({ content: 'Failed to generate summary', category: 'error' });
    }

    await resetDocumentSummaryGeneration(userInteraction);
    throw error;
  }
}

type HighlightResponse = {
  highlights: [string];
};

export async function generateArtificialHighlights(
  documentId: string,
  controller?: AbortController,
): Promise<HighlightResponse> {
  try {
    let signal;
    if (controller) {
      signal = controller.signal;
    }

    const result = await requestWithAuth(
      `${getServerBaseUrl()}/reader/api/create_artificial_highlights_for_document`,
      {
        signal,
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify({ document_id: documentId }),
      },
    );

    return await result.json();
  } catch (error) {
    // Request was aborted, we throw the AbortError
    if (error instanceof DOMException && error.name === 'AbortError') {
      throw error;
    }

    // see if we got a error message through the response object
    if (errorIncludesResponse(error)) {
      const response = await error.response.json();
      createToast({ content: response.error, category: 'error' });
    } else {
      createToast({ content: 'Failed to generate summary', category: 'error' });
    }

    throw error;
  }
}

type TagResponse = {
  tags: [string];
};

export async function generateArtificialTags(
  documentId: string,
  controller?: AbortController,
): Promise<TagResponse> {
  try {
    let signal;
    if (controller) {
      signal = controller.signal;
    }

    const tagNames = (await database.collections.global_tags.findAll()).map((tag) => tag.name);
    const result = await requestWithAuth(
      `${getServerBaseUrl()}/reader/api/create_auto_tags_for_document`,
      {
        signal,
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify({ document_id: documentId, tags: tagNames }),
      },
    );

    return await result.json();
  } catch (error) {
    // Request was aborted, we throw the AbortError
    if (error instanceof DOMException && error.name === 'AbortError') {
      throw error;
    }

    // see if we got a error message through the response object
    if (errorIncludesResponse(error)) {
      const response = await error.response.json();
      createToast({ content: response.error, category: 'error' });
    } else {
      createToast({ content: 'Failed to extract tags', category: 'error' });
    }

    throw error;
  }
}
