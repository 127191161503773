import merge from 'lodash/merge';
import { useEffect, useState } from 'react';

import fallbackPrompts from '../../../copied-from-backend/fallback-prompts-legacy.json';
import { AnyDocument, DocumentWithTransientData, PartialDocument } from '../../types';
import getServerBaseUrl from '../../utils/getServerBaseUrl.platform';
import makeLogger from '../../utils/makeLogger';
import requestWithAuth from '../../utils/requestWithAuth.platformIncludingExtension';
import { getSummaries } from '../ghostreader';
import { globalState } from '../models';
import { resetDocumentSummaryGeneration } from '../stateUpdaters/transientStateUpdaters/documentSummary';
import { generateSummary } from './inference';
import { Prompt, Prompts, PromptScope, PromptType } from './types';

const logger = makeLogger(__filename);

export function useDefaultPrompts() {
  const [defaultPrompts, setDefaultPrompts] = useState<Prompt[]>(fallbackPrompts as Prompt[]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    (async () => {
      try {
        /*
         * The default_prompts endpoint is proxied through the service worker
         * and cached with the stale-while-revalidate policy. We always need
         * prompts to be present, this is the reason we need fallback prompts to
         * also be loaded.
         */
        const result = await requestWithAuth(`${getServerBaseUrl()}/reader/api/default_prompts`, {
          method: 'GET',
          credentials: 'include',
          signal,
        });

        if (!result.ok) {
          return;
        }

        const data = await result.json();
        setDefaultPrompts(data);
      } catch (error) {
        if (error instanceof Error && error.name === 'AbortError') {
          logger.debug('aborted fetching default prompts');
        } else {
          logger.error('failed etching default prompts', { error });
        }
      }
    })();

    return () => {
      controller.abort();
    };
  }, []);

  return defaultPrompts;
}

export function mergePrompts(defaultPrompts: Prompt[], overriddenPrompts?: Prompts) {
  const defaultPromptsByKey = defaultPrompts.reduce((keyedPrompts, prompt) => {
    if (!(prompt.scope in keyedPrompts)) {
      keyedPrompts[prompt.scope] = {};
    }
    const prompts = keyedPrompts[prompt.scope]!;
    prompts[prompt.type] = { ...prompt };
    return keyedPrompts;
  }, {} as Prompts);

  if (!overriddenPrompts) {
    return defaultPromptsByKey;
  }

  return merge(defaultPromptsByKey, overriddenPrompts);
}

export function useOverrideOrRealPrompt(scope: PromptScope, type: PromptType) {
  const overriddenPrompts = globalState((state) => state.persistent.settings.overriddenPrompts);
  const defaultPrompts = useDefaultPrompts();
  const mergedPrompts = mergePrompts(defaultPrompts, overriddenPrompts);
  const prompt = mergedPrompts[scope];
  if (prompt?.[type]) {
    return prompt[type]!;
  }

  throw new Error('Undefined prompt: Make sure to request existing prompt scope and type');
}

/**
 * A document summary isn't just a simple field, it is a field that might
 * contain a value extracted from the document, or a generated summary which is
 * either created manually by the user, or via the auto-summarization feature
 * for power users that bring their own OpenAI key.
 *
 * This hook is a convenience hook, to hide the complexity of dealing with
 * changes to those summaries, as well as handling undo actions, etc. Under all
 * circumstances, it will return a valid summary {@link String}, even the value
 * might be blank.
 */
export function useSummary(
  document:
    | DocumentWithTransientData
    | PartialDocument<AnyDocument, 'summary' | 'generated_summary' | 'category' | 'id' | 'overrides'>,
) {
  const { generatedSummary, summary } = getSummaries(document);
  const isGenerated = Boolean(generatedSummary);
  const isGenerating = globalState((state) => state.isDocumentSummaryGenerating);
  const summarizePrompt = useOverrideOrRealPrompt(PromptScope.Document, PromptType.SummarizeDocument);

  useEffect(() => {
    resetDocumentSummaryGeneration('unknown');
  }, [document.id]);

  const generate = () => generateSummary(document.id, summarizePrompt);

  return { summary, generate, isGenerating, isGenerated, summarizePrompt };
}
